<template>
  <AffiliateLayout>
    <b-card
      class="member-block"
      no-body
      title="Thiết lập list memeber"
    >
      <b-tabs
        v-model="isTab"
        class="mt-2"
        :input="changeTab()"
      >
        <b-tab
          title="Cài đặt"
          class="p-2"
        >
          <setting v-if="isTab === 0" />
        </b-tab>
        <b-tab
          title="Đối soát"
        >
          <list v-if="isTab === 1" />
        </b-tab>
        <b-tab
          title="Chờ thanh toán"
          class="mt-2"
        >
          <wait-payment v-if="isTab === 2" />
        </b-tab>
        <b-tab
          title="Lịch sử thanh toán"
          class="mt-2"
        >
          <history-payment v-if="isTab === 3" />
        </b-tab>
      </b-tabs>

    </b-card>
  </AffiliateLayout>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import AffiliateLayout from '../layouts/AffiliateLayout.vue'
import WaitPayment from './WaitPayment.vue'
import HistoryPayment from './HistoryPayment.vue'
import Setting from './Setting.vue'
import List from './List.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    AffiliateLayout,
    WaitPayment,
    HistoryPayment,
    Setting,
    List,
  },
  data() {
    return {
      isTab: 0,
    }
  },
  created() {
    if (this.$route.query.tab) {
      this.isTab = Number(this.$route.query.tab)
    }
  },
  methods: {
    changeTab() {
      if (this.isTab !== Number(this.$route.query.tab)) {
        this.$router.push(`/affiliate/payment/list?tab=${this.isTab}`)
      }
    },
  },
}
</script>

<style lang="scss">
.member-block{
  .list-title-tab{
    margin-top: 16px;
    display: flex;
    align-items: center;
    .one-item{
      padding: 6px 16px;
      cursor: pointer;
      &.active{
        border-bottom: 2px solid #7367f0;
        color: #7367f0;
      }
    }
  }
}
</style>
