<template>
  <section class="payment-table-list">
    <vue-good-table
      ref="promotion-products"
      class="custom-good-table custom-good-table-ctrl-border commission"
      :columns="fields"
      :rows="listItems"
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span>
          {{ $t(props.column.label) }}
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field === 'stt'"
          class="flex-wrap"
        >
          {{ props.row.originalIndex + 1 }}
        </span>

        <!-- Column: name -->
        <span v-else-if="props.column.field === 'name'">
          <b-link :to="`member/${props.row.customer_id}`">
            {{ props.row.name }}
          </b-link>
        </span>

        <!-- Column: total -->
        <span v-else-if="props.column.field === 'total'">
          {{ unitFormatOriginal(props.row.total) }}
        </span>

        <!-- Column: discount -->
        <span v-else-if="props.column.field === 'discount'">
          {{ unitFormatOriginal(props.row.discount) }}
        </span>

        <!-- Column: more -->
        <span v-else-if="props.column.field === 'more'">
          <div class="text-danger d-flex flex-wrap align-items-center justify-content-center">
            <b-button
              v-b-modal.modal-2
              variant="flat-success"
              class="text-success btn-icon rounded-circle"
              @click="openYes(props.row)"
            >
              Duyệt
            </b-button>
            <b-button
              variant="flat-danger"
              class="btn-icon rounded-circle"
              @click="openNo(props.row)"
            >
              Từ chối
            </b-button>
          </div>
        </span>

      </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap ctrl-pd-1-5 p-2">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','20']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ totalRows }} entries </span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- canh bao modal -->
    <b-modal
      id="modal-canhbao"
      v-model="modal_canhbao"
      title="Duyệt"
      ok-only
      no-close-on-backdrop
      hide-footer
      header-bg-variant="success"
      header-text-variant="light"
      header-close-variant="danger"
    >
      <b-card-text>
        <div class="w-100 modal-profile">
          <b-form-group>
            <div class="title-big-modal text-dark">
              Bạn có chắc duyệt thanh toán này không?
            </div>
          </b-form-group>
          <b-form-group class="d-flex justify-content-center">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.2">
                <path
                  d="M17.5 22.5625H67.5C68.8095 22.5625 70.0654 23.0827 70.9913 24.0087C71.9173 24.9346 72.4375 26.1905 72.4375 27.5V62.5C72.4375 63.8095 71.9173 65.0654 70.9913 65.9913C70.0654 66.9173 68.8095 67.4375 67.5 67.4375H17.5003C15.5302 67.4293 13.6431 66.6431 12.25 65.25C10.8569 63.857 10.0707 61.9699 10.0625 59.9999V20.0001C10.0707 18.0301 10.8569 16.143 12.25 14.75C13.643 13.3569 15.5301 12.5707 17.5001 12.5625H60C60.6465 12.5625 61.2665 12.8193 61.7236 13.2764C62.1807 13.7335 62.4375 14.3535 62.4375 15C62.4375 15.6465 62.1807 16.2665 61.7236 16.7236C61.2665 17.1807 60.6465 17.4375 60 17.4375H17.5C16.8204 17.4375 16.1686 17.7075 15.688 18.188C15.2075 18.6686 14.9375 19.3204 14.9375 20C14.9375 20.6796 15.2075 21.3314 15.688 21.812C16.1686 22.2925 16.8204 22.5625 17.5 22.5625ZM54.1319 48.17C54.7589 48.5889 55.496 48.8125 56.25 48.8125C57.2611 48.8125 58.2309 48.4108 58.9458 47.6958C59.6608 46.9809 60.0625 46.0111 60.0625 45C60.0625 44.246 59.8389 43.5089 59.42 42.8819C59.0011 42.2549 58.4056 41.7663 57.709 41.4777C57.0123 41.1892 56.2458 41.1136 55.5062 41.2608C54.7667 41.4079 54.0873 41.771 53.5542 42.3042C53.021 42.8373 52.6579 43.5167 52.5108 44.2562C52.3637 44.9958 52.4392 45.7623 52.7277 46.459C53.0163 47.1556 53.5049 47.7511 54.1319 48.17Z"
                  fill="#3E444B"
                  stroke="#3E444B"
                  stroke-width="0.125"
                />
              </g>
            </svg>
          </b-form-group>
          <div class="list-btn-block btn-block">
            <div
              class="btn none-bg text-dark"
              @click="modal_canhbao = false"
            >Hủy</div>
            <div
              class="btn"
              @click="yesAction"
            >Duyệt</div>
          </div>
        </div>
      </b-card-text>
    </b-modal>

    <!-- khoa modal -->
    <b-modal
      id="modal-khoa"
      v-model="modal_khoa"
      title="Từ chối thanh toán"
      ok-only
      no-close-on-backdrop
      hide-footer
    >
      <b-card-text>
        <div class="w-100 modal-profile">
          <b-form-group class="d-flex justify-content-center">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M73.9684 58.75L46.4996 11.25C45.842 10.1076 44.8949 9.15873 43.7538 8.49892C42.6127 7.83911 41.3178 7.4917 39.9996 7.4917C38.6815 7.4917 37.3866 7.83911 36.2455 8.49892C35.1043 9.15873 34.1572 10.1076 33.4996 11.25L6.03087 58.75C5.36359 59.8867 5.00896 61.1796 5.00301 62.4976C4.99705 63.8156 5.33998 65.1117 5.99697 66.2543C6.65396 67.3969 7.60162 68.3453 8.7437 69.0032C9.88578 69.661 11.1816 70.005 12.4996 70H67.4996C68.8176 70.005 70.1135 69.661 71.2555 69.0032C72.3976 68.3453 73.3453 67.3969 74.0023 66.2543C74.6593 65.1117 75.0022 63.8156 74.9962 62.4976C74.9903 61.1796 74.6357 59.8867 73.9684 58.75ZM37.4996 32.5C37.4996 31.837 37.763 31.2011 38.2319 30.7323C38.7007 30.2634 39.3366 30 39.9996 30C40.6627 30 41.2985 30.2634 41.7674 30.7323C42.2362 31.2011 42.4996 31.837 42.4996 32.5V45C42.4996 45.6631 42.2362 46.299 41.7674 46.7678C41.2985 47.2367 40.6627 47.5 39.9996 47.5C39.3366 47.5 38.7007 47.2367 38.2319 46.7678C37.763 46.299 37.4996 45.6631 37.4996 45V32.5ZM39.9996 60C39.2579 60 38.5329 59.7801 37.9162 59.3681C37.2996 58.956 36.8189 58.3703 36.5351 57.6851C36.2512 56.9999 36.177 56.2459 36.3217 55.5185C36.4664 54.791 36.8235 54.1228 37.348 53.5984C37.8724 53.0739 38.5406 52.7168 39.268 52.5721C39.9955 52.4274 40.7495 52.5017 41.4347 52.7855C42.1199 53.0693 42.7056 53.55 43.1176 54.1667C43.5297 54.7833 43.7496 55.5084 43.7496 56.25C43.7496 57.2446 43.3545 58.1984 42.6513 58.9017C41.948 59.605 40.9942 60 39.9996 60Z"
                fill="#F75E5E"
              />
            </svg>
          </b-form-group>
          <b-form-group>
            <div class="title-big-modal text-dark">
              Gửi thông báo từ chối thanh toán cho CTV
            </div>
          </b-form-group>
          <b-form-group
            :label="`${$t('Lý do từ chối:')}`"
            label-cols-md="12"
            class="inline-time-group"
          >
            <div class="ctrl-radio">
              <b-form-radio
                v-model="SelectedKTK"
                name="some-radios"
                value="Có hành vi gian lận"
              >
                Có hành vi gian lận
              </b-form-radio>
              <b-form-radio
                v-model="SelectedKTK"
                name="some-radios"
                value="Tài khoản ảo"
              >
                Tài khoản ảo
              </b-form-radio>
              <b-form-radio
                v-model="SelectedKTK"
                name="some-radios"
                value="Có thái độ không tốt với khách hàng"
              >
                Có thái độ không tốt với khách hàng
              </b-form-radio>
              <b-form-radio
                v-model="SelectedKTK"
                name="some-radios"
                value="Nhiều đánh giá không tốt từ khách hàng"
              >
                Nhiều đánh giá không tốt từ khách hàng
              </b-form-radio>
              <b-form-radio
                v-model="SelectedKTK"
                name="some-radios"
                value="Lý do khác"
              >
                Lý do khác
              </b-form-radio>
            </div>
          </b-form-group>
          <div class="list-btn-block btn-block">
            <div
              class="btn none-bg text-dark"
              @click="modal_khoa=false"
            >Hủy</div>
            <div
              class="btn bg-btn"
              @click="noAction"
            >Gửi</div>
          </div>
        </div>
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import {
  BButton,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormRadio,
  BCardText,
  BLink,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Vue from 'vue'
import MixinAffiliate from '../mixin'

Vue.mixin(MixinAffiliate)

export default {
  components: {
    BCardText,
    BButton,
    BPagination,
    BFormSelect,
    BFormGroup,
    VueGoodTable,
    BFormRadio,
    BLink,
  },
  data() {
    return {
      modal_canhbao: false,
      modal_khoa: false,
      SelectedKTK: '',
      fields: [
        {
          field: 'stt', label: 'STT', tdClass: 'text-left', thClass: 'text-left', sortable: false,
        },
        {
          field: 'name', label: 'Tên đầy đủ', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
        {
          field: 'total', label: 'Doanh số', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
        {
          field: 'discount', label: 'Chiết khấu', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
        {
          field: 'more', label: 'Hành Động', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
      ],
      listItems: [],
      pageLength: 5,
      currentPage: 1,
      totalRows: 20,
      yesPrams: {
        fullname: '',
        customer_id: null,
        commision: null,
        order_id: null,
      },
      noPrams: {
        fullname: '',
        customer_id: null,
        commision: null,
        order_id: null,
        note: '',
      },
    }
  },
  created() {
    this.getOrder()
  },
  methods: {
    async getOrder() {
      const res = await this.mixGetAffi('/api/v1/for_control', { is_for_control: 0 })
      if (res.status) {
        this.listItems = []
        this.totalRows = res.data.items.length
        res.data.items.map(val => {
          this.listItems.push({
            order_id: val.order_id,
            total: val.revenue,
            discount: val.affiliate_commisions,
            name: val.fullname,
            status: 0,
            customer_id: val.customer_id,
          })
          return true
        })
      }
    },

    openYes(item) {
      this.yesPrams = {
        fullname: item.name,
        customer_id: item.customer_id,
        commision: item.discount,
        order_id: item.order_id.toString(),
        type: 1,
      }
      this.modal_canhbao = true
    },

    openNo(item) {
      this.noPrams = {
        fullname: item.name,
        customer_id: item.customer_id,
        commision: item.discount,
        order_id: item.order_id.toString(),
        type: 3,
        note: this.SelectedKTK,
      }
      this.modal_khoa = true
    },

    async yesAction() {
      const res = await this.mixPostAffi('/api/v1/for_control', this.yesPrams)
      if (res.status) {
        this.getOrder()
        this.modal_canhbao = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Congratulation !',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Update success',
          },
        })
      }
    },

    async noAction() {
      const res = await this.mixPostAffi('/api/v1/for_control', this.noPrams)
      if (res.status) {
        this.getOrder()
        this.modal_khoa = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Congratulation !',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Update success',
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
.payment-table-list{
  .custom-good-table-ctrl-border{
    .vgt-table{
      border: 1px solid #fff !important;
    }
    td{
      padding: 0.75rem !important;

      &:first-child {
        padding-left: 1.5rem !important;
      }
      &:last-child {
        padding-right: 1.5rem !important;
      }
    }
    thead{
      background-color: #f3f2f7
    }
  }
}

.list-btn-block{
  display: flex;
  align-items: center;
  gap: 16px;
  &.btn-block{
    justify-content: space-between;
    margin-top: 24px;
    padding-bottom: 8px;
    .btn{
      width: calc(50% -  8px);
    }
  }
  .form-group{
    margin-bottom: 0;
  }
  .btn{
    background-color: #269780;
    color: #fff;
    border: 1px solid #269780;
    &:hover{
      opacity: 0.8;
    }
    &.none-bg{
      background-color: #fff;
      border: 0.5px solid rgba(62, 68, 75, 0.2);
      /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16); */

      &:hover{
        background-color: #ccc;
      }
    }
    &.bg-btn{
      background-color: #F75E5E;
      border: 1px solid #F75E5E;
      &:hover{
        color: #fff;
        opacity: 0.8;
      }
    }
  }
}

#modal-canhbao{
  .modal-header{
    background-color: #269780 !important;
    .modal-title{
      color: #fff;
    }
  }
  .title-big-modal{
    font-size: 20px;
    color: #7367f0;
    font-weight: bold;
  }
  .modal-profile{
    .one-item{
      margin-bottom: 16px;
      .title-info{
        font-size: 13px;
        color: #3E444B;
        margin-bottom: 4px;
      }
      .content-info{
        font-size: 16px;
        color: #3E444B;
        font-weight: bold;
      }
    }
  }
}

#modal-khoa{
  .modal-header{
    background-color: #F75E5E;
    .modal-title{
      color: #fff;
    }
  }
  .ctrl-radio{
    display: flex;
    flex-direction: column;
    gap: 8px;
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: rgba(62, 68, 75, 0.3);;
      background-color: #ECA124;
    }
    .custom-control-input:not(:disabled) {
      &:active ~ .custom-control-label {
        &::before {
          background-color: #ECA124;
          border-color: #ECA124;
        }
      }
    }
  }
  .title-big-modal{
    font-size: 20px;
    color: #7367f0;
    font-weight: bold;
  }
  .modal-profile{
    .one-item{
      margin-bottom: 16px;
      .title-info{
        font-size: 13px;
        color: #3E444B;
        margin-bottom: 4px;
      }
      .content-info{
        font-size: 16px;
        color: #3E444B;
        font-weight: bold;
      }
    }
  }
}
</style>
