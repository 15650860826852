<template>
  <section class="wait-payment-list">
    <div class="list-btn-block justify-content-end p-2">
      <div class="d-flex justify-content-end align-items-end">
        <vue-excel-xlsx
          :data="jsonData"
          :columns="columns"
          :file-name="xlsxFilename"
          :file-type="'xlsx'"
          :sheet-name="'sheetname'"
          class="btn btn-secondary"
        >
          Xuất file thanh toán
        </vue-excel-xlsx>
        <b-form-group
          :label="`*${$t('Kỳ đối soát')}`"
          label-cols-md="12"
          class="inline-time-group ml-2"
        >
          <flat-pickr
            v-model="orderDatePicker.fromToDate"
            class="form-control d-inline-block"
            :config="configDateRangePicker"
            placeholder="Ngày bắt đầu và kết thúc"
            style="min-width: 220px;"
          />
        </b-form-group>
      </div>
    </div>
    <vue-good-table
      ref="promotion-products"
      class="custom-good-table custom-good-table-ctrl-border commission"
      :columns="fields"
      :rows="listItems"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span>
          {{ $t(props.column.label) }}
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field === 'id'"
          class="flex-wrap"
        >
          {{ props.row.id }}
        </span>

        <!-- Column: percent_discount -->
        <span v-else-if="props.column.field === 'name'">
          {{ props.row.name }}
        </span>

        <!-- Column: fixed_costs -->
        <span v-else-if="props.column.field === 'total'">
          {{ unitFormatOriginal(props.row.total) }}
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'date_comparison'">
          {{ props.row.date_comparison }}
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'bank_name'">
          {{ props.row.bank_name }}
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'account_bank_number'">
          <span
            v-clipboard:copy="props.row.account_bank_number"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            class="text-primary cursor-pointer"
          >{{ props.row.account_bank_number }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'more'">
          <div class="text-danger d-flex flex-wrap align-items-center justify-content-center">
            <b-button
              v-b-modal.modal-2
              variant="flat-success"
              class="text-success btn-icon rounded-circle"
              @click="handleAccept(props.row)"
            >
              Xác nhận
            </b-button>
          </div>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>

      </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap ctrl-pd-1-5 p-2">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','20']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ totalRows }} entries </span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- xac nhan modal -->
    <b-modal
      id="modal-canhbao"
      v-model="modal_pay"
      title="Duyệt"
      ok-only
      no-close-on-backdrop
      hide-footer
      header-bg-variant="success"
      header-text-variant="light"
      header-close-variant="danger"
    >
      <b-card-text>
        <div class="w-100 modal-profile">
          <b-form-group>
            <div class="img-avata">
              <feather-icon
                icon="CreditCardIcon"
                size="64"
                class="text-success"
              />
            </div>
          </b-form-group>
          <b-form-group>
            <label>*Mã giao dịch</label>
            <b-form-input
              v-model="payParam.trading_code"
              class="py-2"
            />
          </b-form-group>
          <b-form-group
            :label="`${$t('Nội dung thanh toán:')}`"
            label-cols-md="12"
            class="inline-time-group"
          >
            <div class="ctrl-radio">
              <b-form-radio
                v-for="(item, index) in contentPayment"
                :key="index"
                v-model="payParam.note"
                name="some-radios"
                :value="item.id"
              >
                {{ item.name }}
              </b-form-radio>
              <b-form-input
                v-model="reason"
                :readonly="payParam.note === 'Khác' ? false : true"
              />
            </div>
          </b-form-group>
          <div class="list-btn-block btn-block">
            <div
              class="btn none-bg text-dark"
              @click="modal_pay=false"
            >Hủy</div>
            <div
              class="btn"
              @click="handleSubmitPay"
            >Duyệt</div>
          </div>
        </div>
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import {
  // BCardHeader,
  BButton,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormRadio,
  BCardText,
  BFormInput,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
import Vue from 'vue'
import VueExcelXlsx from 'vue-excel-xlsx'
import MixinAffiliate from '../mixin'

Vue.use(VueExcelXlsx)

Vue.mixin(MixinAffiliate)

export default {
  components: {
    // ValidationObserver,
    BCardText,
    // BCardHeader,
    BButton,
    BPagination,
    BFormSelect,
    BFormGroup,
    VueGoodTable,
    BFormRadio,
    BFormInput,
    flatPickr,
  },
  data() {
    return {
      unitPrice: MixinAffiliate.unitFormatOriginal,
      jsonData: [],
      columns: [
        {
          label: 'id',
          field: 'id',
        },
        {
          label: 'customer_id',
          field: 'customer_id',
        },
        {
          label: 'order_id',
          field: 'order_id',
        },
        {
          label: 'fullname',
          field: 'fullname',
        },
        {
          label: 'created_at',
          field: 'created_at',
        },
        {
          label: 'commision',
          field: 'commision',
        },
        {
          label: 'bank_name',
          field: 'bank_name',
        },
        {
          label: 'bank_account_number',
          field: 'bank_account_number',
        },
        {
          label: 'trading_code',
          field: 'trading_code',
        },
        {
          label: 'note',
          field: 'note',
        },
      ],
      xlsxFilename: 'data',
      modal_pay: false,
      fields: [
        {
          field: 'id', label: 'ID', tdClass: 'text-left', thClass: 'text-left', sortable: false,
        },
        {
          field: 'name', label: 'Tên CTV', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
        {
          field: 'date_comparison', label: 'Ngày đối soát', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
        {
          field: 'total', label: 'Số tiền thanh toán', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
        {
          field: 'bank_name', label: 'Ngân hàng', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
        {
          field: 'account_bank_number', label: 'Số tài khoản', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
        {
          field: 'more', label: 'Hành Động', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
      ],
      listItems: [],
      contentPayment: [
        {
          id: 'Thanh toán chiết khấu',
          name: 'Thanh toán chiết khấu',
        },
        {
          id: 'Thanh toán tiền thưởng',
          name: 'Thanh toán tiền thưởng',
        },
        {
          id: 'Khác',
          name: 'Khác',
        },
      ],
      selectedReason: null,
      reason: '',
      invoice_code: null,
      pageLength: 10,
      currentPage: 1,
      totalRows: 0,
      orderDatePicker: {
        startDate: null,
        endDate: null,
        fromToDate: null,
      },
      configDateRangePicker: {
        mode: 'range',
        dateFormat: 'd-m-Y',
        locale: Vietnamese,
        onChange: async ([start, end]) => {
          if (start && end) {
            this.orderDatePicker.startDate = start.toLocaleDateString('en-ZA').replaceAll('/', '-')
            this.orderDatePicker.endDate = end.toLocaleDateString('en-ZA').replaceAll('/', '-')
            this.getList()
          }
        },
      },
      payParam: {
        fullname: '',
        customer_id: '',
        commision: '',
        order_id: '',
        type: 2,
        note: '',
      },
    }
  },
  created() {
    this.getList()
  },

  methods: {
    setDefaultDateRange(day = 0) {
      const d = new Date()
      const time = d.getTime()
      const d2 = new Date(time - (1000 * 60 * 60 * 24 * day))
      this.configDateRangePicker.defaultDate = [d2.toISOString(), d.toISOString()]
      this.orderDatePicker.startDate = `${d2.getFullYear()}-${d2.getMonth() + 1}-${d2.getDate()}`
      this.orderDatePicker.endDate = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
    },

    handleAccept(item) {
      this.payParam = {
        fullname: item.name,
        customer_id: item.customer_id,
        commision: item.commision,
        order_id: item.order_id.toString(),
        type: 2,
        note: '',
        trading_code: '',
      }
      this.modal_pay = true
    },

    async getList() {
      const params = {
        type: 1,
      }
      if (this.orderDatePicker.startDate) {
        params.date_from = this.orderDatePicker.startDate
      }
      if (this.orderDatePicker.endDate) {
        params.date_to = this.orderDatePicker.endDate
      }
      const res = await this.mixGetAffi('/api/v1/for_controls', params)
      if (res.status) {
        this.totalRows = res.data.items.length
        this.listItems = []
        this.jsonData = []
        this.xlsxFilename = 'payment_list'
        res.data.items.map(val => {
          this.listItems.push({
            ...val,
            id: val.id,
            name: val.fullname,
            date_comparison: val.created_at,
            total: val.commision,
            bank_name: val.bank_name,
            account_bank_number: val.bank_account_number,
          })
          this.jsonData.push({
            id: val.id,
            customer_id: val.customer_id,
            order_id: `[${val.order_id}]`,
            fullname: val.fullname,
            created_at: val.created_at,
            commision: val.commision,
            bank_name: val.bank_name,
            bank_account_number: `[${val.bank_account_number}]`,
            trading_code: '',
            note: '',
          })
          return true
        })
      }
    },

    async handleSubmitPay() {
      const resF = true
      const params = { ...this.payParam }
      params.note = params.note === 'Khác' ? this.reason : params.note
      if (params.note === '') {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Cảnh báo !',
            icon: 'XIcon',
            variant: 'danger',
            text: 'Vui lòng nhập nội dung thanh toán',
          },
        })
        return resF
      }
      if (params.trading_code === '') {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Cảnh báo !',
            icon: 'XIcon',
            variant: 'danger',
            text: 'Vui lòng nhập Mã giao dịch',
          },
        })
      }

      const res = await this.mixPostAffi('/api/v1/for_control_change_status', params)
      if (res.status) {
        this.modal_pay = false
        this.getList()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Congratulation !',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Update success',
          },
        })
      }
      return resF
    },

    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Copy Số tài khoản thành công!',
          icon: 'BellIcon',
        },
      })
    },

    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Copy thất bại! Vui lòng thử lại',
          icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.wait-payment-list{
  .custom-good-table.commission {
    .flex-wrap {
      display: flex;
      gap: 1rem 0;
      flex-direction: row;
      flex-wrap: wrap;
    }
    strong {
      cursor: pointer;
    }
  }
  .custom-good-table-ctrl-border{
    .vgt-table{
      border: 1px solid #fff !important;
    }
    td{
      padding: 0.75rem !important;

      &:first-child {
        padding-left: 1.5rem !important;
      }
      &:last-child {
        padding-right: 1.5rem !important;
      }
    }
    thead{
      background-color: #f3f2f7
    }
  }
}

#modal-canhbao{
  .ctrl-radio{
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .modal-header{
    background-color: #ECA124;
    .modal-title{
      color: #fff;
    }
  }
  .title-big-modal{
    font-size: 20px;
    color: #7367f0;
    font-weight: bold;
  }
  .modal-profile{
    .one-item{
      margin-bottom: 16px;
      .title-info{
        font-size: 13px;
        color: #3E444B;
        margin-bottom: 4px;
      }
      .content-info{
        font-size: 16px;
        color: #3E444B;
        font-weight: bold;
      }
    }
  }
}
</style>
