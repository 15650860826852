<template>
  <section>
    <div class="list-btn-block justify-content-end p-2">
      <div class="d-flex justify-content-end align-items-end">
        <b-button @click="handImport">
          Import file thanh toán
        </b-button>
        <input
          id="myImportFile"
          class="hidden"
          type="file"
          @change="importXl($event)"
        >
        <b-form-group
          :label="`*${$t('Kỳ đối soát')}`"
          label-cols-md="12"
          class="inline-time-group ml-2"
        >
          <flat-pickr
            v-model="orderDatePicker.fromToDate"
            class="form-control d-inline-block"
            :config="configDateRangePicker"
            placeholder="Ngày bắt đầu và kết thúc"
            style="min-width: 220px;"
          />
        </b-form-group>
      </div>
    </div>
    <vue-good-table
      ref="promotion-products"
      class="custom-good-table custom-good-table-ctrl-border commission"
      :columns="fields"
      :rows="listItems"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span>
          {{ $t(props.column.label) }}
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field === 'id'"
          class="flex-wrap"
        >
          {{ props.row.id }}
        </span>

        <!-- Column: percent_discount -->
        <span v-else-if="props.column.field === 'name'">
          {{ props.row.name }}
        </span>

        <!-- Column: fixed_costs -->
        <span v-else-if="props.column.field === 'total'">
          {{ unitFormatOriginal(props.row.total) }}
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'date_comparison'">
          {{ props.row.date_comparison }}
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'bank_name'">
          {{ props.row.bank_name }}
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'account_bank_number'">
          <span
            v-clipboard:copy="props.row.account_bank_number"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            class="text-primary cursor-pointer"
          >{{ props.row.account_bank_number }}</span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap ctrl-pd-1-5 p-2">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','20']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ totalRows }} entries </span>
          </div>
          <div>
            <b-pagination
              v-model="currentPageOriginal"
              :total-rows="totalRows"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </section>
</template>

<script>
import {
  BButton,
  BPagination,
  BFormSelect,
  BFormGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import flatPickr from 'vue-flatpickr-component'
import Vue from 'vue'
import MixinAffiliate from '../mixin'

Vue.mixin(MixinAffiliate)

export default {
  components: {
    BButton,
    BPagination,
    BFormSelect,
    BFormGroup,
    VueGoodTable,
    flatPickr,
  },
  data() {
    return {
      unitPrice: MixinAffiliate.unitFormatOriginal,
      orderDatePicker: {
        startDate: null,
        endDate: null,
        fromToDate: null,
      },
      configDateRangePicker: {
        mode: 'range',
        dateFormat: 'd-m-Y',
        locale: Vietnamese,
        onChange: async ([start, end]) => {
          if (start && end) {
            this.orderDatePicker.startDate = start.toLocaleDateString('en-ZA').replaceAll('/', '-')
            this.orderDatePicker.endDate = end.toLocaleDateString('en-ZA').replaceAll('/', '-')
            this.getList()
          }
        },
      },
      modal_canhbao: false,
      modal_khoa: false,
      model: {
        use_date: null,
        list: [],
        type: 1,
        percent_discount: 0,
        fixed_costs: 0,
        selectedHang: [],
        selectedKhac: [],
      },
      fields: [
        {
          field: 'id', label: 'ID', tdClass: 'text-left', thClass: 'text-left', sortable: false,
        },
        {
          field: 'invoice_code', label: 'Mã giao dịch', tdClass: 'text-left', thClass: 'text-left', sortable: false,
        },
        {
          field: 'name', label: 'Tên CTV', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
        {
          field: 'created_at', label: 'Ngày đối soát', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
        {
          field: 'total', label: 'Số tiền thanh toán', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
        {
          field: 'bank_name', label: 'Ngân hàng', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
        {
          field: 'account_bank_number', label: 'Số tài khoản', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
        {
          field: 'content', label: 'Nội dung', tdClass: 'text-left', thClass: 'text-left', sortable: false,
        },
      ],
      listItems: [],
      contentPayment: [
        {
          id: 1,
          name: 'Thanh toán chiết khấu',
        },
        {
          id: 2,
          name: 'Thanh toán tiền thưởng',
        },
        {
          id: '#',
          name: 'Khác',
        },
      ],
      selectedReason: null,
      reason: '',
      invoice_code: null,
      pageLength: 10,
      currentPageOriginal: 1,
      totalRows: 10,
      isEdit: false,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    handImport() {
      document.getElementById('myImportFile').click()
    },
    async importXl(e) {
      const res = await this.mixFormDataAffi('/api/v1/for_control_import_excel', {
        file: e.target.files[0],
      })
      if (res.status) {
        this.getList()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Congratulation !',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Update success',
          },
        })
      }
    },

    async getList() {
      const params = {
        type: 2,
      }
      if (this.orderDatePicker.startDate) {
        params.date_from = this.orderDatePicker.startDate
      }
      if (this.orderDatePicker.endDate) {
        params.date_to = this.orderDatePicker.endDate
      }
      const res = await this.mixGetAffi('/api/v1/for_controls', params)
      if (res.status) {
        this.totalRows = res.data.items.length
        this.listItems = []
        res.data.items.map(val => {
          this.listItems.push({
            ...val,
            id: val.id,
            invoice_code: val.trading_code,
            name: val.fullname,
            created_at: val.created_at,
            total: val.commision,
            bank_name: val.bank_name,
            account_bank_number: val.bank_account_number,
            content: val.note,
          })
          return true
        })
      }
    },

    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Copy Số tài khoản thành công!',
          icon: 'BellIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Copy thất bại! Vui lòng thử lại',
          icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.custom-good-table.commission {
  .flex-wrap {
    display: flex;
    gap: 1rem 0;
    flex-direction: row;
    flex-wrap: wrap;
  }
  strong {
    cursor: pointer;
  }
}
.custom-good-table-ctrl-border{
  .vgt-table{
    border: 1px solid #fff !important;
  }
  td{
    padding: 0.75rem !important;

    &:first-child {
      padding-left: 1.5rem !important;
    }
    &:last-child {
      padding-right: 1.5rem !important;
    }
  }
  thead{
    background-color: #f3f2f7
  }
}
</style>
