<template>
  <validation-observer
    ref="form"
  >
    <b-form @submit.prevent>
      <b-row>
        <b-col md="12">
          <b-form-group
            :label="`*` + $t('Kỳ đối soát') + `:`"
            label-cols-md="2"
          >
            <validation-provider
              #default="{ errors }"
              name="Kỳ đối soát"
              rules="required"
            >
              <b-form-input
                v-model="stData.name_comparing"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('Kỳ đối soát')"
                class="w-25"
                readonly
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="`*` + $t('Ngày đối soát') + `:`"
            label-cols-md="2"
          >
            <div class="d-flex">
              <validation-provider
                #default="{ errors }"
                name="Ngày đối soát"
                rules="required"
              >
                <b-form-input
                  v-model="stData.day_comparing[0]"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Ngày đối soát 1')"
                  class="w-100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="Ngày đối soát"
                rules="required"
              >
                <b-form-input
                  v-model="stData.day_comparing[1]"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Ngày đối soát 2')"
                  class="w-100 ml-2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </b-form-group>
          <hr>
          <b-form-group
            :label="`*` + $t('Thu nhập tối thiểu') + `:`"
            label-cols-md="2"
          >
            <b-form-input
              v-model="stData.money_min"
              type="number"
              :placeholder="$t('Thu nhập tối thiểu')"
              class="w-25"
              @keyup.native="checkMoney"
            />
            <!-- <cleave
              v-model="stData.money_min"
              class="form-control w-25"
              :options="{
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
              }"
              :placeholder="$t('Thu nhập tối thiểu')"
              @keyup.native="checkMoney"
            /> -->
          </b-form-group>
          <b-form-group
            :label="`*` + $t('Phương thức thanh toán') + `:`"
            label-cols-md="2"
          >
            <validation-provider
              name="Phương thức thanh toán"
            >
              <span>Ngân hàng</span>
            </validation-provider>
          </b-form-group>
          <b-button @click="upSet">
            Lưu
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      stData: {
        money_min: 50000,
        day_comparing: [15, 30],
        name_comparing: '2 kỳ/tháng',
        has: false,
      },
      stKey: 'comparing_checking',
    }
  },
  mounted() {
    this.getSetting()
  },
  methods: {
    async getSetting() {
      const res = await this.mixGet(`/setting/${this.stKey}`)
      if (res.status) {
        this.stData = { ...JSON.parse(res.data.value), has: true }
      }
    },
    async upSet() {
      this.$refs.form.validate().then(async success => {
        if (!success) {
          return
        }
        const params = {
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          key: this.stKey,
          value: JSON.stringify(this.stData),
        }
        let res = null
        if (this.stData.has) {
          res = await this.mixPut(
            `/setting/${this.stKey}`,
            params,
          )
        } else {
          res = await this.mixPost(
            '/settings',
            params,
          )
        }
        if (res.status) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Congratulation !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Update affiliate setting success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.error, ',')),
            },
          })
        }
        this.getSetting()
      })
    },
    checkMoney() {
      this.stData.money_min = Number(this.stData.money_min)
      if (this.stData.money_min < 0) {
        this.stData.money_min = 0
      }
    },
  },
}
</script>
